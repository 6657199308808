import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Dispatch } from "@store";

import { Span, SpanContainer } from "@common";
import { changeDeliveryStatus, updateToNextStatus } from "@api";

import { EXPRESS } from "@constants";
import { Modal } from "antd";

type Props = {
  setUpdateStatusModal: Function;
  updateStatusModal: boolean;
  orderId: string | null;
  status: string;
  refresh?: any;
};

export const UpdateStatusModal = ({
  setUpdateStatusModal,
  updateStatusModal,
  status,
  refresh,
}: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const params: any = useParams();
  const id = params.id;
  const { confirm } = Modal;
 
  const handleUpdateStatus = async () => {
    setUpdateStatusModal(false);
    try {
      dispatch.UI.setIsLoading(true);
      const res = await updateToNextStatus(id);
      if (res?.status === 200) {
        dispatch.UI.setIsLoading(false);
        dispatch.UI.setAlert({
          message: `Status successfully updated`,
          type: "Success",
        });
      }
    } catch (err: any) {
      console.log(JSON.stringify(err));
      dispatch.UI.setIsLoading(false);
      const errMsg = err?.response?.data?.message ?? "Unable to update status!";
      dispatch.UI.setAlert({ message: errMsg, type: "Error" });
    }
  };

  const showConfirm = () => {
    confirm({
      title: "Change delivery status",
      content: (
        <Span>
          Are you sure you want to change the status&nbsp;
          <SpanContainer color="green-1">{EXPRESS[status]}</SpanContainer> ?
        </Span>
      ),
      onOk() {
        handleUpdateStatus();
      },
      onCancel() {
        setUpdateStatusModal(false);
      },
    });
  };

  return <>{updateStatusModal && showConfirm()}</>;
};
