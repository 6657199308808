/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";
import { Dispatch } from "../../store";
import { useDispatch } from "react-redux";

import { Form, Modal, Select } from "antd";
import { CANCEL_REASON } from "@constants";
import { cancelBookingByOrderId, changeDeliveryStatus } from "@api";

type Props = {
  setCancelModal: Function;
  cancelModal: boolean;
  orderId: string;
  status?: string;
  refresh?: any;
};

export const CancelModal = ({
  setCancelModal,
  cancelModal,
  orderId,
  status,
  refresh,
}: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [form] = Form.useForm();
  const { Option } = Select;

  const [reason, setReason] = useState("");

  const handleCancel = async () => {
    try {
      setCancelModal(false);
      dispatch.UI.setIsLoading(true);

      if (status === "canceled") {
        const res = await changeDeliveryStatus(orderId, status, reason);
        if ([201, 200].includes(res.status)) {
          dispatch.UI.setIsLoading(false);
          dispatch.UI.setAlert({
            message: `Status successfully updated to canceled`,
            type: "Success",
          });
          refresh();
        }
      } else {
        const res = await cancelBookingByOrderId(orderId, reason);
        if ([201, 200].includes(res.status)) {
          dispatch.UI.setIsLoading(false);
          dispatch.UI.setAlert({
            message: `Status successfully updated to canceled`,
            type: "Success",
          });
        }
      }
    } catch (err: any) {
      dispatch.UI.setIsLoading(false);
      const errMsg = err?.response?.data?.message ?? "Unable to cancel order!";
      dispatch.UI.setAlert({ message: errMsg, type: "Error" });
    }
  };

  return (
    <>
      <Modal
        title="Are you sure you want to cancel?"
        visible={cancelModal}
        onOk={form.submit}
        okText="Cancel"
        okButtonProps={{ danger: true }}
        onCancel={() => setCancelModal(false)}
      >
        <Form
          onFinish={handleCancel}
          layout="vertical"
          wrapperCol={{ span: 24 }}
          form={form}
        >
          <Form.Item name="Reason" label="" rules={[{ required: true }]}>
            <Select
              placeholder="Please select reason"
              onChange={(e) => setReason(e)}
            >
              {CANCEL_REASON?.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
