import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import "../../containers/DriverManagement/AllDrivers/Driver.css";
import { Dispatch } from "@store";

import {
  ListenForOutsideClicks,
  ApproveModal,
  PendingModal,
  RejectModal,
} from "@components";

import edit_icon from "../../assets/images/edit_icon.png";
import approved_icon from "../../assets/images/approved_icon.png";
import pending_icon from "../../assets/images/pending_icon.png";
import reject_icon from "../../assets/images/reject_icon.png";
import { AiOutlineMore } from "react-icons/ai";

import { Paper, Zoom } from "@mui/material";

type Props = {
  id: string;
  status: string;
  dataType: string;
};

const StatusDriver = ({ id, status, dataType }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const [open, setOpen] = useState(false);
  const [listening, setListening] = useState(false);
  const menuRef: any = useRef(null);

  useEffect(ListenForOutsideClicks(listening, setListening, menuRef, setOpen));

  const [approveModal, setApproveModal] = useState(false);
  const handleApprove = (e) => {
    e.preventDefault();
    setApproveModal(true);
  };

  const [pendingModal, setPendingModal] = useState(false);
  const handlePending = (e) => {
    e.preventDefault();
    setPendingModal(true);
  };

  const [rejectModal, setRejectModal] = useState(false);
  const handleReject = (e) => {
    e.preventDefault();
    setRejectModal(true);
  };

  const handleEdit = () => {
    dispatch.UI.resetForm("editDriver");
    dispatch.UI.updateFormId({
      target: "editDriver",
      value: id,
    });
  };

  return (
    <>
      <div
        onClick={() => setOpen(!open)}
        className="z-50 p-2 flex justify-center items-center border-solid border-[1px] relative border-gray-400 hover:border-gray-600 rounded-full mr-2"
      >
        <div ref={menuRef}>
          <AiOutlineMore />
        </div>

        <Zoom in={open}>
          <Paper elevation={5} className="pop_over z-50">
            <div
              className="flex transition-all hover:scale-105 p-2"
              onClick={handleEdit}
            >
              <img
                src={edit_icon}
                // placeholder={edit_icon}
                className="status_icon"
              />
              <span>Edit</span>
            </div>

            {status !== "approved" && (
              <>
                <div
                  className="flex transition-all hover:scale-105 p-2"
                  onClick={handleApprove}
                >
                  <img
                    src={approved_icon}
                    // placeholder={approved_icon}
                    className="status_icon"
                  />
                  <span>Approved</span>
                </div>

                {status !== "pending" && (
                  <div
                    className="flex transition-all hover:scale-105 p-2"
                    onClick={handlePending}
                  >
                    <img
                      src={pending_icon}
                      // placeholder={pending_icon}
                      className="status_icon"
                    />
                    <span>Pending</span>
                  </div>
                )}

                {status !== "rejected" && (
                  <div
                    className="flex transition-all hover:scale-105 p-2"
                    onClick={handleReject}
                  >
                    <img
                      src={reject_icon}
                      // placeholder={reject_icon}
                      className="status_icon"
                    />
                    <span>Reject</span>
                  </div>
                )}
              </>
            )}
          </Paper>
        </Zoom>
      </div>

      <ApproveModal
        setApproveModal={setApproveModal}
        approvedModal={approveModal}
        id={id}
        dataType={dataType}
      />
      <PendingModal
        setPendingModal={setPendingModal}
        pendingModal={pendingModal}
        id={id}
        dataType={dataType}
      />
      <RejectModal
        setRejectModal={setRejectModal}
        rejectModal={rejectModal}
        id={id}
        dataType={dataType}
      />
    </>
  );
};

export default StatusDriver;
